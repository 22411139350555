import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

class RazaroonCard extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    fluidObject: PropTypes.any,
  }

  render() {
    const { fluidObject, title, name } = this.props

    const razaroonImage = getImage(fluidObject)

    const razaroonGalleryCard = {
      position: "relative",
      background: "#0a3228",
    }

    const razaroonGalleryCardCharacterFrame = {
      position: "relative",
    }

    const razaroonGalleryCardCharacterFramePadding = {
      paddingBottom: "100%",
    }

    const razaroonGalleryCardCharacter = {
      position: "absolute",
      left: "0",
      top: "0",
      bottom: "0",
      right: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2rem",
      maxWidth: "100%",
    }

    const razaroonGalleryCardImage = {
      maxWidth: "100%",
      maxHeight: "100%",
    }

    const razaroonGalleryCardTitle = {
      background: "#0B4B3F",
      padding: "0.75rem 1rem",
      alignSelf: "flexStart",
      textAlign: "center",
      fontWeight: "500",
    }

    const razaroonGalleryCardTitleSpan = {
      opacity: "0.6",
      display: "inline-block",
      marginRight: "0.325em",
    }

    return (
      <div style={razaroonGalleryCard}>
        <div style={razaroonGalleryCardCharacterFrame}>
          <div style={razaroonGalleryCardCharacterFramePadding}>
            <GatsbyImage
              style={razaroonGalleryCardCharacter}
              image={razaroonImage}
              alt="razroo image"
            />
          </div>
        </div>
        <div style={razaroonGalleryCardTitle}>
          <span style={razaroonGalleryCardTitleSpan}>{name}</span>
          {title}
        </div>
      </div>
    )
  }
}

export default RazaroonCard
