import React from "react"
import Layout from "../components/layout"
import Menu from "../components/global/Menu"
import Footer from "../components/global/Footer"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import RazaroonCard from "../components/global/RazaroonCard"

const RazaroonsPage = () => {
  const razaroonsData = useStaticQuery(graphql`
    query razaroonsQuery {
      allStrapiRazaroons {
        nodes {
          id
          title
          name
          razaroonImage {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  height: 500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout bodyTheme="theme-books">
      <SEO
        title="Razaroons"
        description="Razroo Cartoons"
        keywords={[`Razaroon`, `Razaroons`, `Razroo`, `Kangaroos`, `Cartoons`]}
        imageUrl={
          razaroonsData.allStrapiRazaroons.nodes[0]["razaroonImage"][
            "localFile"
          ]["publicURL"]
        }
      />
      <header>
        <Menu />
      </header>
      <main className="page-wrapper">
        <section className="section section-first">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading text-center">
                  <div className="section-heading-text">
                    <h1>Razaroon Gallery</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-room" data-sidebar-anchor="true">
                  <div className="blog-room-list">
                    <div className="grid grid-1 grid-sm-2 grid-lg-4 grid-small-articles">
                      {razaroonsData.allStrapiRazaroons.nodes.map((node, i) => {
                        return (
                          <div className="grid-item">
                            <RazaroonCard
                              key={i}
                              fluidObject={node["razaroonImage"]["localFile"]}
                              title={node["title"]}
                              name={node["name"]}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </Layout>
  )
}

export default RazaroonsPage
